import Box from '@gtg/components/box'
import Container from '@gtg/components/container'
import Footer from '@gtg/components/footer'
import Nav from '@gtg/components/nav'
import Seo from '@gtg/components/seo'
import media from '@gtg/utils/media-queries'
import React from 'react'
import styled from 'styled-components'
import GlobalWrapper from '../components/globalWrapper'
import TentSrc from '../img/aboutus.jpg'

const TentImg = styled.div`
  width: 100%;
  height: 600px;
  background: url(${TentSrc});
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  ${media.md`
    height: 450px;
  `}
  ${media.sm`
    height: 300px;
  `}
`

const AboutUs = () => {
  return (
    <GlobalWrapper>
      <Seo title="Über uns" />
      <Nav caps wMax="xl" withBorder />
      <section>
        <Container>
          <Box pxMax="md" pyMax="lg">
            <h1>Über uns</h1>
            <p>
              „Stretchzelt Bayern“ ist ein Angebot der Hytide GmbH, die Events
              im Münchner Norden veranstaltet.
            </p>
            <p>
              Aus kleineren Veranstaltungen sind über die Jahre
              Open-Air-Festivals mit bis zu 10.000 Besuchern geworden.
            </p>
            <p>
              Für diese Veranstaltungen haben wir zusätzlich zu gemieteten
              Material nach und nach einen Bestand an Stretchzelten und anderem
              Equipment aufgebaut.
            </p>
            <p>
              Gerade Stretchzelte waren in Bayern nicht einfach zu bekommen -
              dabei sind sie sowohl optisch, als auch funktional herkömmlichen
              Event-Pavillons um Längen voraus.
            </p>
            <p>
              Wir freuen uns daher unser über viele Jahre aufgebautes Wissen und
              Material jetzt auch auf vielen weiteren Veranstaltungen zum
              Einsatz zu bringen und zum Gelingen Ihrer Events beizutragen.
            </p>
          </Box>
        </Container>
        <TentImg />
      </section>
      <Footer background="#0c1821" />
    </GlobalWrapper>
  )
}

export default AboutUs
